import React from "react";
import { Link } from "react-router-dom";
import ExpandableParagraph from "./ExpandableParagraph";
function Team({ img, teamName, text }) {
  return (
    <div className="bg-white rounded-lg shadow-lg w-full">
      <div
        className=" w-full h-44 bg-no-repeat bg-cover bg-center rounded-t-lg content-center"
        style={{ backgroundImage: `url(${img})` }}
      ></div>
      <div className=" bg-white rounded-lg shadow-lg p-4 w-full">
        <h3 className="text-base md:text-lg font-extrabold md:font-bold mb-2 text-purple-600 md:text-purple-700 hover:text-purple-800 transition-colors duration-300">
          <span className="bg-gradient-to-r from-purple-400 to-pink-500 bg-clip-text text-transparent">
            {teamName}
          </span>
        </h3>
        <ExpandableParagraph text={text} />
        <Link
          to={"/apply"}
          className="inline-block bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded"
        >
          Apply to join
        </Link>
      </div>
    </div>
  );
}

export default Team;
