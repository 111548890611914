import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import { useNavigate } from "react-router-dom";

function ApplicationForm() {
  const form = useRef();
  const navigate = useNavigate();
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_7mn392d",
        "template_h9zx8b8",
        form.current,
        "e0iEH4XmsdD8-lWBy"
      )
      .then(
        (result) => {
          console.log(result.text);
          alert("Application Succesfull.Thanks for applying");
          navigate("/");
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <div>
      <div className="bg-gray-800 min-h-screen flex flex-col py-4">
        <div className="container max-w-sm mx-auto flex-1 flex flex-col items-center justify-center px-2">
          <div className="bg-white px-4 py-4 rounded shadow-md text-black w-full">
            <h1 className="mb-5 text-3xl text-center">Apply Here</h1>
            <form ref={form} onSubmit={sendEmail} id="apply">
              <input
                type="text"
                className="block border border-grey-light w-full p-2 rounded mb-4"
                name="fullname"
                placeholder="Full Name"
              />

              <input
                type="text"
                className="block border border-grey-light w-full p-2 rounded mb-4"
                name="email"
                placeholder="Email"
              />
              <input
                type="tel"
                className="block border border-grey-light w-full p-2 rounded mb-4"
                name="phone"
                placeholder="Phone number"
              />

              <input
                type="text"
                className="block border border-grey-light w-full p-2 rounded mb-4"
                name="portfolio"
                placeholder="Portfolio link (optional)"
              />
              <select
                id="apply"
                className="block border border-gray-300 bg-white text-gray-900 w-full p-2 rounded mb-4 appearance-none focus:outline-none focus:border-blue-500"
                name="team"
              >
                <option disabled selected>
                  Choose Your Team
                </option>
                <option value="Web Development Team">Web Developer Team</option>
                <option value="Mobile App team">Mobile App Developer</option>
                <option value="Desktop App Team">Desktop App developer</option>
                <option value="Graphic Designing Team">Graphic Designer</option>
                <option value="Machin Learning Team">Machine Learning</option>
                <option value="Cybersecurity Team">Cybersecurity</option>
              </select>

              <textarea
                id="message"
                rows="4"
                class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Tell us something about yourself..."
                name="about"
              ></textarea>

              <input
                type="submit"
                value="Complete your application"
                className="w-full text-center py-2 rounded bg-green-800 text-white hover:bg-green-500 focus:outline-none my-2 font-bold"
              />
            </form>

            <div className="text-center text-sm text-grey-dark mt-4">
              By signing up, you agree to the
              <a
                className="no-underline border-b border-grey-dark text-grey-dark"
                href="#"
              >
                Terms of Service
              </a>{" "}
              and
              <a
                className="no-underline border-b border-grey-dark text-grey-dark"
                href="#"
              >
                Privacy Policy
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ApplicationForm;
