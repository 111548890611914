import React from "react";

function Testimonial() {
  return (
    <div className="bg-white rounded-lg shadow-lg p-6">
      <p className="mb-4">
        " About our community About our community About our community About our
        community About our community About our community About our community
        About our community"
      </p>
      <p className="font-bold">- hello Hell 29, AASTU</p>
    </div>
  );
}

export default Testimonial;
