import React from "react";
import { Link } from "react-router-dom";

function About() {
  return (
    <div>
      <div className="bg-gray-100 shadow-lg rounded-lg p-6 md:p-8">
        <h2 className="text-xl md:text-3xl font-bold mb-4 md:mb-6 text-gray-800 text-center">
          AASTU Developers Community
        </h2>
        <p className="text-lg md:text-xl text-gray-700 leading-relaxed mb-6">
          At the Developer Community, we believe that collaboration and
          innovation are key to success in the field of technology. Our
          community brings together individuals with diverse backgrounds and
          expertise to create a supportive and collaborative environment that
          fosters learning, growth, and innovation. With teams specializing in
          mobile app development, web development, machine learning, desktop app
          development, and other areas of technology, we offer a wide range of
          opportunities for members to learn from each other and work on
          real-world projects. Our goal is to build a community of passionate
          and skilled developers who can work together to solve complex problems
          and drive progress in the field of technology.
        </p>
        <div className="flex justify-center mt-6">
          <Link to="/apply">
            <button className="bg-blue-500 hover:bg-blue-600 text-white font-medium py-2 px-4 rounded-lg transition-colors duration-300">
              Join Us Today
            </button>
          </Link>
        </div>
      </div>
      ;
    </div>
  );
}

export default About;
