import React, { useRef, useState } from "react";
import Team from "./Team";
import webImg from "../imgs/website.jpeg";
import machineLearnignImg from "../imgs/machinelearning.jpeg";
import graphics from "../imgs/graphics.jpeg";
import mobileApp from "../imgs/mobileapp.jpeg";
import desktopApp from "../imgs/desktopApp.jpeg";
import cyberSecurity from "../imgs/cyberSecurity.jpeg";
import dev_team from "../imgs/alexandre-debieve-FO7JIlwjOtU-unsplash.jpg";
import Testimonial from "./Testimonial";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import YouTubeIcon from "@material-ui/icons/YouTube";
import TwitterIcon from "@material-ui/icons/Twitter";
import FacebookIcon from "@material-ui/icons/Facebook";
import CopyrightIcon from "@material-ui/icons/Copyright";
import logo from "../imgs/logo.png";
import { Link } from "react-router-dom";
function Home() {
  const [isOpen, setIsOpen] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const contactsRef = useRef(null);

  function handleContactClick() {
    contactsRef.current.scrollIntoView({ behavior: "smooth" });
  }
  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };
  function handleCombinedClick() {
    handleContactClick();
    toggleMenu();
  }
  return (
    <div className="bg-gray-100">
      <div
        className="text-center w-full h-auto bg-no-repeat bg-cover bg-center content-center"
        style={{ backgroundImage: `url(${dev_team})` }}
      >
        <div className=" bg-black bg-opacity-50 w-full px-0">
          <header className=" text-white">
            <div className=" px-2 py-3 flex items-center justify-between w-full">
              <div className=" ">
                <img src={logo} alt="Logo" className=" lg:h-24 h-16" />
              </div>
              <div className="md:hidden">
                <MenuIcon onClick={toggleMenu} />
              </div>
              <div className=" hidden md:block">
                <div className="px-4 py-3 flex items-center justify-between">
                  <ul className="flex font-bold">
                    <li className="mr-4">
                      <Link to="/about">About Us</Link>
                    </li>
                    <li className="mr-4">
                      <Link>Blog</Link>
                    </li>
                    <li className="mr-4">
                      <Link>Forum</Link>
                    </li>
                    <li className="mr-4">
                      <Link onClick={handleContactClick}>Contact Us</Link>
                    </li>
                  </ul>
                  <Link
                    to="/apply"
                    className=" bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded"
                  >
                    Apply Here
                  </Link>
                </div>
              </div>
            </div>
            {showMenu && (
              <div
                className={`fixed z-50 bg-black justify-center inset-0 ${
                  showMenu ? "opacity-100" : "opacity-0 pointer-events-none"
                } transition-all duration-500`}
              >
                <div className="md:hidden justify-items-end">
                  <CloseIcon onClick={toggleMenu} />
                </div>
                <div className="flex flex-col text-white">
                  <Link
                    to="/about"
                    className="mt-20 text-lg font-bold text-white hover:text-gray-300 transition-colors duration-300 border-b-2 border-transparent hover:border-gray-300 py-2"
                  >
                    About Us
                  </Link>
                  <Link className=" text-lg font-bold text-white hover:text-gray-300 transition-colors duration-300 border-b-2 border-transparent hover:border-gray-300 py-2">
                    Forum
                  </Link>
                  <Link className="text-lg font-bold text-white hover:text-gray-300 transition-colors duration-300 border-b-2 border-transparent hover:border-gray-300 py-2">
                    Blog
                  </Link>
                  <Link
                    className="text-lg font-bold text-white hover:text-gray-300 transition-colors duration-300 border-b-2 border-transparent hover:border-gray-300 py-2"
                    onClick={handleCombinedClick}
                  >
                    Contact Us
                  </Link>
                  <Link
                    to="/apply"
                    className="w-1/2 text-center mt-10 m-auto bg-gradient-to-r from-purple-500 to-indigo-500 text-white font-bold py-2 px-4 rounded-lg shadow-lg hover:bg-gradient-to-r hover:from-purple-600 hover:to-indigo-600 transition-all duration-300"
                  >
                    Apply Here
                  </Link>
                </div>
              </div>
            )}
          </header>

          <section className="text-center text-white w-full h-auto py-20 bg-no-repeat bg-cover bg-center  content-center">
            <h1 className=" text-4xl md:text-5xl font-extrabold mb-8 ">
              BE YOUR <span className=" text-blue-700">BEST</span>{" "}
            </h1>
            <p className="text-xl mb-8">
              Learn with us and let's excel together
            </p>
            <Link
              to="/apply"
              className="inline-block bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded mb-8"
            >
              JOIN TODAY
            </Link>
          </section>
        </div>
      </div>

      <main className="container mx-auto md:px-4  px-2 py-6">
        <section className=" px-2">
          <div className="my-12 md:flex w-full lg:justify-between lg:px-10 lg:mx-5">
            <div className=" w-full  lg:mx-5">
              <h2 className="text-3xl md:text-4xl font-bold mb-8 text-center text-indigo-600 leading-tight tracking-wide">
                Learning each other
              </h2>
              <p className="text-lg md:text-xl font-medium text-gray-700 leading-relaxed my-6 md:my-8 md:px-10">
                This section is designed to help members of the community get to
                know each other and learn about their interests, skills, and
                experiences. Through various icebreaker activities and group
                discussions, members can build connections with each other and
                form a supportive network of peers.
              </p>
            </div>
            <img
              src="https://codewithmosh.com/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Ffeature-05%402x.c788aad2.png&w=1080&q=75"
              className=" w-full h-60"
            />
          </div>

          <div className="my-12 md:flex w-full lg:justify-between lg:px-10 lg:mx-5">
            <img
              src="https://codewithmosh.com/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Ffeature-02%402x.5cf5e26a.png&w=1080&q=75"
              alt=""
              className=" hidden md:block  w-full h-72"
            />
            <div className=" w-full  lg:mx-5">
              <h2 className="text-3xl md:text-4xl font-bold mb-8 text-center text-indigo-600 leading-tight tracking-wide">
                Code night
              </h2>
              <p className=" text-lg md:text-xl font-medium text-gray-700 leading-relaxed my-6 md:my-8 md:px-10">
                Code night is a regular event where members of the community
                come together to work on coding projects, often for an extended
                period of time. This event provides an opportunity for members
                to collaborate, share ideas, and learn from each other in a
                supportive and creative environment.
              </p>
            </div>
            <img
              src="https://codewithmosh.com/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Ffeature-02%402x.5cf5e26a.png&w=1080&q=75"
              alt=""
              className=" md:hidden w-full h-72"
            />
          </div>

          <div className="my-12 md:flex w-full lg:justify-between lg:px-10 ">
            <div className=" w-full  lg:mx-5">
              <h2 className="text-3xl md:text-4xl font-bold mb-8 text-center text-indigo-600 leading-tight tracking-wide">
                Solving algorithms
              </h2>
              <p className=" text-lg md:text-xl font-medium text-gray-700 leading-relaxed my-6 md:my-8 md:px-10">
                This is focused on improving members' coding skills and
                problem-solving abilities. Through the exploration of algorithms
                and coding challenges, members can sharpen their abilities to
                think critically and logically when approaching complex
                problems.
              </p>
            </div>
            <img
              src="https://codewithmosh.com/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Ffeature-03%402x.0028f5d1.png&w=828&q=75"
              alt=""
              className=" w-full h-96 "
            />
          </div>

          <div className="my-12 md:flex w-full lg:justify-between lg:px-10 lg:mx-5">
            <img
              src="https://codewithmosh.com/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Ffeature-04%402x.a95119f3.png&w=750&q=75"
              alt=""
              className=" hidden md:block  w-full h-72"
            />
            <div className=" w-full  lg:mx-5">
              <h2 className="text-3xl md:text-4xl font-bold mb-8 text-center text-indigo-600 leading-tight tracking-wide">
                Real-world projects
              </h2>
              <p className=" text-lg md:text-xl font-medium text-gray-700 leading-relaxed my-6 md:my-8 md:px-10">
                This section provides members of the community with
                opportunities to work on real-world coding projects in teams.
                These projects may be sponsored by companies or organizations,
                and can offer valuable experience in developing and deploying
                code in a professional setting.
              </p>
            </div>
            <img
              src="https://codewithmosh.com/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Ffeature-04%402x.a95119f3.png&w=750&q=75"
              alt=""
              className=" md:hidden w-full h-72"
            />
          </div>
        </section>

        <section className="my-12 container mx-auto px-4 py-6">
          <h2 className="font-extrabold md:text-5xl text-transparent text-3xl  text-center py-5 bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600">
            OUR TEAMS
          </h2>
          <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
            <Team
              img={webImg}
              teamName="Web Development"
              text="Take your web development skills to the next level! Our team is dedicated to creating stunning and functional websites that push the boundaries of what's possible online. Join us and be part of a community of like-minded individuals who are passionate about making an impact through technology."
            />
            <Team
              img={machineLearnignImg}
              teamName="Machin Learning"
              text="Discover the possibilities of AI with our Machine Learning team! As we develop cutting-edge algorithms and models, we're unlocking new insights and opportunities in a wide range of industries. Join us and be part of a community of like-minded individuals who are shaping the future of technology through machine learning."
            />
            <Team
              img={graphics}
              teamName="Graphic Designing"
              text="Join our Graphics Designing team and be part of a community of talented individuals who are passionate about creating visually stunning designs that captivate and inspire. Whether you're interested in UI/UX design, branding, or illustration, our team has something for you. We work on a wide range of projects, from designing logos and websites to creating marketing materials and product packaging. Join us and be part of a team that's dedicated to pushing the boundaries of what's possible through creative design."
            />
            <Team
              img={mobileApp}
              teamName="Mobile App Development"
              text="Ready to create the next big thing in mobile apps? Our team is passionate about crafting innovative and user-friendly apps that make a difference in our daily lives. Join us and be part of a community of like-minded individuals who are dedicated to pushing the boundaries of what's possible through mobile app development."
            />
            <Team
              img={desktopApp}
              teamName="Desktop App Development"
              text="Join our Desktop App Development team and be part of a community of talented individuals who are committed to creating powerful and intuitive apps that meet the needs of modern users. Whether you're interested in desktop computing or software development, our team has something for you."
            />
            <Team
              img={cyberSecurity}
              teamName="Cybersecurity"
              text="Ready to make a difference in the world of cybersecurity? Our team is committed to protecting individuals and organizations from cyber threats through innovative solutions and strategies. Join us and be part of a community of like-minded individuals who are dedicated to keeping the digital world safe and secure."
            />
          </div>
        </section>

        <section
          className=" bg-slate-200 w -full mt-12 p-12 rounded shadow-lg"
          ref={contactsRef}
        >
          <h2 className="text-2xl font-bold mb-8 text-center">
            GET IN TOUCH TODAY!
          </h2>
          <div className=" md:flex justify-between md:px-20">
            <p className="mb-4 text-center text-gray-600 font-semibold text-lg flex flex-col items-center justify-center">
              EMAIL ADDRESS
              <br />
              <a
                href="mailto:adc@info.com"
                className="text-indigo-500 hover:underline transition-colors duration-300"
              >
                info@adc.merkastu.com
              </a>
            </p>
            <p className="text-center text-gray-600 font-semibold text-lg">
              PHONE NUMBER
              <br />
              <a
                href="tel:+251967292497"
                className="text-indigo-500 hover:underline transition-colors duration-300"
              >
                +251 967292497
              </a>
            </p>
          </div>
        </section>
      </main>

      <footer className="bg-gray-900 border-t-2 border-gray-200 py-4">
        <div className="bg-gray-900 py-6">
          <div className="container mx-auto text-center md:flex md:justify-between md:px-10">
            <div className="mb-4 md:mb-0">
              <p className="text-gray-400">
                <span className=" px-2">
                  <CopyrightIcon />
                </span>
                <span className="text-indigo-500 font-bold text-lg">AASTU</span>{" "}
                <span className="font-semibold text-lg text-white">
                  Developers Community
                </span>
              </p>
            </div>
            <div className="mb-4 md:mb-0">
              <Link className="text-gray-400 hover:text-white mr-4 transition-colors duration-300 rounded-full p-2 bg-blue-500">
                <FacebookIcon className="inline-block w-5 h-5 fill-current" />
              </Link>
              <Link className="text-gray-400 hover:text-white mr-4 transition-colors duration-300 rounded-full p-2 bg-red-500">
                <YouTubeIcon className="inline-block w-5 h-5 fill-current" />
              </Link>
              <Link className="text-gray-400 hover:text-white transition-colors duration-300 rounded-full p-2 bg-blue-400">
                <TwitterIcon className="inline-block w-5 h-5 fill-current" />
              </Link>
            </div>
            <div className="flex justify-center md:justify-end">
              <p className="text-gray-400">
                <Link className="hover:text-white mr-4 transition-colors duration-300">
                  <span className="font-semibold">Terms of Use</span>
                </Link>
                <Link className="hover:text-white transition-colors duration-300">
                  <span className="font-semibold">Privacy Policy</span>
                </Link>
              </p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Home;
