import { useState } from "react";

function ExpandableParagraph(props) {
  const [expanded, setExpanded] = useState(false);

  function toggleExpanded() {
    setExpanded(!expanded);
  }

  return (
    <div className="mb-4">
      <p
        className={
          "text-lg text-gray-700 font-medium leading-relaxed" +
          (expanded ? "" : " line-clamp-2")
        }
      >
        {props.text}
      </p>
      {expanded && (
        <button
          className="text-purple-700 font-medium hover:underline"
          onClick={toggleExpanded}
        >
          Read less
        </button>
      )}
      {!expanded && (
        <button
          className="text-purple-700 font-medium hover:underline"
          onClick={toggleExpanded}
        >
          Read more...
        </button>
      )}
    </div>
  );
}
export default ExpandableParagraph;
